import React, { useState, useEffect, useContext } from 'react';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import { UserContext } from '../../UserContext';
import { toast } from 'react-toastify';

function Discount() {
    const [discount, setDiscount] = useState<number>(0);  // Initial value set to 0
    const [loading, setLoading] = useState(false);
    const [discountId, setDiscountId] = useState<string | null>(null); // To store the discount ID
    const {token} = useContext(UserContext);

    // Fetch the current discount
    const getDiscount = async () => { 
        try {
            const response = await fetch('https://backend.livecosmetics.pro/api/salonDiscount/getSalonDiscount');
            const discountInfo = await response.json();

            if (discountInfo && discountInfo[0]?.discount) {
                setDiscount(discountInfo[0].discount); // Set the discount value
                setDiscountId(discountInfo[0]._id);    // Save the discount ID
            } else {
                console.error("No discount found in response");
            }
        } catch (error) {
            console.error("Failed to fetch discount:", error);
        }
    };

    // Update the discount
    const updateDiscount = async () => {
        if (!discountId) return; // No discount ID available

        setLoading(true);
        try {
            const response = await fetch(`https://backend.livecosmetics.pro/api/salonDiscount/${discountId}/updateSalonDiscount`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify({ discount }),
            });



            if (response.ok) {
                toast.success('Discount updated successfully');
            } else {
                toast.error('Failed to update discount');
            }
        } catch (error) {
            console.error('Error updating discount:', error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch the discount when the component mounts
    useEffect(() => {
        getDiscount();
    }, []);

    return (
        <div className='flex flex-col items-center w-full h-full overflow-auto py-10'>
            <Header title={'Discount'} button={false} buttonTitle={''} action={''} />
            <div className="w-11/12 rounded-2xl bg-WhiteBg shadow-md pl-12 justify-between pr-12 mt-6 font-poppins">
                <input
                    type='number'
                    placeholder='Price'
                    value={discount}
                    onChange={(e) => setDiscount(parseFloat(e.target.value))}
                    className='mt-4 p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-gray-300 rounded-md'
                />
                <div>
                    <button
                        disabled={loading}
                        onClick={updateDiscount}  // Call the updateDiscount function on click
                        className="mt-4 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-blue-500 focus:outline-none hover:bg-blue-500 mb-5"
                    >
                        {loading ? (
                            <div className='w-full h-full items-center justify-center flex'>
                                <Loader size='small' />
                            </div>
                        ) : (
                            "Edit"
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Discount;
