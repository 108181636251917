import React from 'react'
import { ReactComponent as SeachIcon } from '../Images/GroupSeachIcon.svg';

type Props = {
    search: string,
    setSearch: (c: string) => void,
    placeholderText: string
}

const Search = ({search, setSearch, placeholderText}: Props) => {
  return (
    <div className='h-32 w-full flex items-center flex-shrink-0 '>
        <div className='flex w-full bg-[#0F2C4E0A] h-16 rounded-lg px-10 items-center'>
            <SeachIcon className='mr-5' />
            <input
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder={`Search ${placeholderText}`}
                className="text-[#15151580] font-semibold bg-transparent outline-none w-full"
            />
        </div>
        {/* <button className='w-40 flex items-center justify-around text-sm text-TextBlue font-semibold border-[#E5E5E5] ml-5'>
            <FilterIcon />
            <p className='text-TextBlue font-medium underline'>Advanced filter</p>
        </button> */}
    </div>
  )
}

export default Search