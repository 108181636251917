import React, { useEffect, useState, useContext } from 'react';
import Header from '../../components/Header';
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { UserContext } from '../../UserContext'; // Assume the token comes from this context
import Loader from '../../components/Loader';

interface CarouselDTO { 
  _id: string,
  dataUrl: string,
  type: string
}

const Carousel = () => {
  const [carouselItems, setCarouselItems] = useState<CarouselDTO[]>([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { token } = useContext(UserContext); // Get the token from UserContext

  // Fetch Carousel items
  const fetchCarouselItems = async () => {
    try {
      const response = await fetch('https://backend.livecosmetics.pro/api/carousel/getCarousel', {
        headers: {
          'Authorization': `${token}` // Include the token in headers
        }
      });
      if (!response.ok) throw new Error('Failed to fetch carousel items');
      const data = await response.json();
      console.log(data)
      setCarouselItems(data);
      setLoading(false);
    } catch (error) {
      toast.error('Failed to load carousel items');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCarouselItems();
  }, [token]);

  const handleEdit = (carouselId: any) => {
    navigate(`/carousel/edit/${carouselId}`);
  };

  const handleDelete = async (carouselId: string) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this carousel item?');
    if (confirmDelete) {
      try {
        // Call the delete carousel API
        const response = await fetch(`https://backend.livecosmetics.pro/api/carousel/${carouselId}/deleteCarousel`, {
          method: 'DELETE',
          headers: {
            'Authorization': `${token}`, // Include the token in the request headers
            'Accept': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Failed to delete carousel item');
        }

        toast.success("Carousel item deleted successfully!");
        fetchCarouselItems(); // Re-fetch carousel items after deletion
      } catch (error) {
        toast.error((error as Error).message);
      }
    }
  };

  return (
    <div className='flex flex-col items-center w-full h-full overflow-auto py-10'>
      <Header title={'Carousel'} button={true} buttonTitle={'Add Item'} action={'/carousel/add'} />
      <div className="w-11/12 rounded-2xl bg-WhiteBg shadow-md pl-12 justify-between pr-12 mt-6 font-poppins">
        <div className='w-full overflow-visible flex-grow'>
          <div className="w-full overflow-visible">
            <div className='w-full grid grid-cols-4 font-bold text-mainTextColor my-4'>
              <div>Image</div>
              <div>Actions</div>
            </div>
            <hr className='border-t border-[#cdcdd0]' />
            {
              loading ?
                <div className='items-center justify-center flex w-full h-[200px]'>
                  <Loader size={'large'} />
                </div> :
                carouselItems.map((item, index) => (
                  <div key={item._id} className='w-full grid grid-cols-4 my-4'>
                    <div className='flex items-center'>
                    {
                      item.type === 'image' ? (
                        <img src={item.dataUrl} alt='carousel' className='w-20 h-20' />
                      ) : (
                        <video className='w-20 h-20' muted autoPlay loop playsInline>
                          <source src={item.dataUrl} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      )
                    }

                    </div>
                    <div className='flex items-center space-x-4'>
                      <button onClick={() => handleEdit(item._id)} className='text-[#1490C2] underline'>Edit</button>
                      <button onClick={() => handleDelete(item._id)} className='text-[#C41E3A]'>Delete</button>
                    </div>
                  </div>
                ))
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
