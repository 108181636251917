// Import the necessary Firebase modules
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDNyD9JjRo54SuyCD0fOANy2ts4LLDhdoQ",
  authDomain: "livecosmetics-dc139.firebaseapp.com",
  projectId: "livecosmetics-dc139",
  storageBucket: "livecosmetics-dc139.appspot.com",
  messagingSenderId: "128154147641",
  appId: "1:128154147641:web:3cf5abeec98852421b66e8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and export it
export const auth = getAuth(app);
