import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router';
import { toast } from "react-toastify";
import HeaderWithBack from '../../components/HeaderWithBack';
import { UserContext } from '../../UserContext'; // Assuming you have a UserContext where token is stored

type Props = {};

interface Category {
  _id: string;
  name: string;
}

const AddCategory: React.FC<Props> = () => {
  const navigate = useNavigate();
  
  // Access the token from UserContext
  const { token } = useContext(UserContext);

  const [categoryName, setCategoryName] = useState<string>('');
  const [categoryImage, setCategoryImage] = useState<File | null>(null);
  const [parentCategoryId, setParentCategoryId] = useState<string>('');
  const [subCategoryId, setSubCategoryId] = useState<string>('');
  const [isParent, setIsParent] = useState<boolean>(false);
  const [isSub, setIsSub] = useState<boolean>(false);
  const [isChild, setIsChild] = useState<boolean>(false);
  const [parentCategories, setParentCategories] = useState([]); // Ensure array initialization
  const [subCategories, setSubCategories] = useState([]); // Ensure array initialization
  const [loadingParentCategories, setLoadingParentCategories] = useState<boolean>(true);
  const [loadingSubCategories, setLoadingSubCategories] = useState<boolean>(false);

  // Fetch parent categories on component mount
  useEffect(() => {
    const fetchParentCategories = async () => {
      setLoadingParentCategories(true);
      try {
        const response = await fetch('https://backend.livecosmetics.pro/api/category/getParentCategories', {
          headers: {
            'Authorization': `${token}` 
          }
        });
        if (!response.ok) throw new Error('Failed to fetch parent categories');
        const data = await response.json();
        console.log(data.data)
        setParentCategories(data.data);
      } catch (error) {
        toast.error('Failed to fetch parent categories');
      } finally {
        setLoadingParentCategories(false); // Set loading to false when done
      }
    };

    if (token) {
      fetchParentCategories(); // Only fetch if token exists
    }
  }, [token]);

  // Fetch subcategories when a parent category is selected
  useEffect(() => {
    if (parentCategoryId && isChild) {
      const fetchSubCategories = async () => {
        setLoadingSubCategories(true);
        try {
          const response = await fetch(`https://backend.livecosmetics.pro/api/category/getSubCategories/${parentCategoryId}`, {
            headers: {
              'Authorization': `${token}` // Include the token in the request headers
            }
          });
          if (!response.ok) throw new Error('Failed to fetch subcategories');
          const data = await response.json();
          setSubCategories(data.data);
        } catch (error) {
          toast.error('Failed to fetch subcategories');
        } finally {
          setLoadingSubCategories(false); // Set loading to false when done
        }
      };

      if (token) {
        fetchSubCategories(); // Only fetch if token exists
      }
    }
  }, [parentCategoryId, isChild, token]);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setCategoryImage(file);
    }
  };

  const handleDeleteImage = () => {
    setCategoryImage(null);
  };

  const handleAddCategory = async () => {
    if (categoryName === '' || categoryImage == null) {
      toast.error("Please fill in all required fields before creating the category");
      return;
    }

    const formData = new FormData();
    formData.set("title", categoryName);
    formData.set("image", categoryImage, categoryImage.name);

    if (isSub || isChild) {
      formData.set("parentCategoryId", isChild ? subCategoryId : parentCategoryId);
    }

    formData.set("isParent", isParent.toString());
    formData.set("isSub", isSub.toString());
    formData.set("isChild", isChild.toString());

    Array.from(formData.entries()).forEach(([key, value]) => {
      console.log(`${key}: ${value}`);
    });

    try {
      const response = await fetch("https://backend.livecosmetics.pro/api/category/createCategory", {
        method: "POST",
        body: formData,
        headers: {
          'Authorization': `${token}` 
        }
      });

      if (!response.ok) {
        throw new Error("Failed to create category");
      }

      toast.success("Category created successfully!");
      navigate('/categories');
    } catch (e) {
      toast.error((e as Error).message);
    }
  };

  return (
    <div className='flex flex-col w-full h-full overflow-auto items-center py-10'>
      <HeaderWithBack previousPage={'Categories'} currentPage={'Add Category'} previousPageUrl={'/categories'} />
      <div className='bg-WhiteBg shadow-md w-11/12 px-12 py-6 mt-6 rounded-2xl'>
        <div className='flex items-center space-x-4'>
          <label htmlFor='category-image' className='bg-[#1490C2] text-white rounded-md p-2 cursor-pointer'>
            Choose Image
          </label>
          <input
            type='file'
            id='category-image'
            accept='image/*'
            onChange={handleImageChange}
            className='hidden'
          />
          {categoryImage && (
            <div className='flex items-center space-x-4'>
              <img src={URL.createObjectURL(categoryImage)} alt='Category' className='w-20 h-20' />
              <button onClick={handleDeleteImage} className='bg-[#C41E3A] text-white rounded-md p-2'>
                Delete Image
              </button>
            </div>
          )}
        </div>
        <input
          type='text'
          placeholder='Category Name'
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
          className='mt-4 p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-primary rounded-md'
        />

        {/* Parent Dropdown for Sub or Child Category */}
        {((isSub || isChild) && parentCategories.length > 0) && (
          <>
            {loadingParentCategories ? (
              <div className="text-gray-500 mt-4">Loading parent categories...</div>
            ) : (
              <select
                value={parentCategoryId}
                onChange={(e) => setParentCategoryId(e.target.value)}
                className='mt-4 p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-primary rounded-md'
                disabled={isParent}
              >
                <option value=''>Select Parent Category</option>
                {parentCategories?.map((parent:any) => (
                  <option key={parent._id} value={parent._id}>
                    {parent.title}
                  </option>
                ))}
              </select>
            )}
          </>
        )}

        {/* Sub Dropdown for Child Category */}
        {isChild && parentCategoryId && parentCategories.length > 0 && (
          <>
            {loadingSubCategories ? (
              <div className="text-gray-500 mt-4">Loading subcategories...</div>
            ) : (
              <select
                value={subCategoryId}
                onChange={(e) => setSubCategoryId(e.target.value)}
                className='mt-4 p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-primary rounded-md'
              >
                <option value=''>Select Sub Category</option>
                {subCategories?.map((sub:any) => (
                  <option key={sub._id} value={sub._id}>
                    {sub.title}
                  </option>
                ))}
              </select>
            )}
          </>
        )}

        {/* Category Type - Parent, Sub, or Child */}
        <div className='flex items-center space-x-4 mt-4'>
          <label>
            <input
              type="checkbox"
              checked={isParent}
              onChange={() => {
                setIsParent(!isParent);
                setIsSub(false);
                setIsChild(false);
                setParentCategoryId('');
                setSubCategoryId('');
              }}
              className='mr-2'
            />
            Parent Category
          </label>

          <label>
            <input
              type="checkbox"
              checked={isSub}
              onChange={() => {
                setIsSub(!isSub);
                setIsParent(false);
                setIsChild(false);
                setParentCategoryId('');
                setSubCategoryId('');
              }}
              className='mr-2'
            />
            Sub Category
          </label>

          <label>
            <input
              type="checkbox"
              checked={isChild}
              onChange={() => {
                setIsChild(!isChild);
                setIsParent(false);
                setIsSub(false);
                setParentCategoryId('');
                setSubCategoryId('');
              }}
              className='mr-2'
            />
            Child Category
          </label>
        </div>

        <button onClick={handleAddCategory} className='bg-[#1490C2] text-white rounded-md p-2 mt-4 w-full'>
          Add Category
        </button>
      </div>
    </div>
  );
};

export default AddCategory;
