import React, { useEffect, useState, useContext } from 'react';
import Header from '../../components/Header';
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { UserContext } from '../../UserContext'; // Assume the token comes from this context
import Loader from '../../components/Loader';

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { token } = useContext(UserContext); // Get the token from UserContext

  const fetchCategories = async () => {
    try {
      const response = await fetch('https://backend.livecosmetics.pro/api/category/getCategories', {
        headers: {
          'Authorization': `${token}` // Include the token in headers
        }
      });
      if (!response.ok) throw new Error('Failed to fetch categories');
      const data = await response.json();
      setCategories(data);
      setLoading(false);
    } catch (error) {
      toast.error('Failed to load categories');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, [token]);

  const handleEdit = (categoryId: any) => {
    navigate(`/categories/edit/${categoryId}`);
  };

  const handleDelete = async (categoryId: string) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this category?');
    if (confirmDelete) {
      try {
        // Call the delete category API
        const response = await fetch(`https://backend.livecosmetics.pro/api/category/${categoryId}/deleteCategory`, {
          method: 'DELETE',
          headers: {
            'Authorization': `${token}`, // Include the token in the request headers
            'Accept': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Failed to delete category');
        }

        toast.success("Category deleted successfully!");
        fetchCategories(); // Re-fetch categories after deletion
      } catch (error) {
        toast.error((error as Error).message);
      }
    }
  };

  // Recursive function to flatten the categories with numbering
  const flattenCategories = (categories: any[], parentNumber: string = ''): any[] => {
    let flatList: any[] = [];
    categories.forEach((category, index) => {
      const currentNumber = parentNumber ? `${parentNumber}.${index + 1}` : `${index + 1}`;
      flatList.push({
        ...category,
        number: currentNumber
      });

      if (category.subcategoryHierarchy && category.subcategoryHierarchy.length > 0) {
        flatList = flatList.concat(flattenCategories(category.subcategoryHierarchy, currentNumber));
      }
    });
    return flatList;
  };

  // Flatten the categories list for display
  const flatCategories = flattenCategories(categories);

  return (
    <div className='flex flex-col items-center w-full h-full overflow-auto py-10'>
      <Header title={'Categories'} button={true} buttonTitle={'Add category'} action={'/categories/add'} />
      <div className="w-11/12 rounded-2xl bg-WhiteBg shadow-md pl-12 justify-between pr-12 mt-6 font-poppins">
        <div className='w-full overflow-visible flex-grow'>
          <div className="w-full overflow-visible">
            <div className='w-full grid grid-cols-4 font-bold text-mainTextColor my-4'>
              <div>Number</div>
              <div>Name</div>
              <div>Image</div>
              <div>Actions</div>
            </div>
            <hr className='border-t border-[#cdcdd0]' />
            {
              loading ?
                <div className='items-center justify-center flex w-full h-[200px] bg-red-500'>
                  <Loader size={'large'} />
                </div> :
                flatCategories.map((item) => (
                  <div key={item._id} className='w-full grid grid-cols-4 my-4'>
                    <div className='text-TextBlue flex items-center'> {item.number}</div>
                    <div className='text-TextBlue flex items-center'>
                      <p className='underline'>{item.title}</p>
                    </div>
                    <div className='flex items-center'>
                      <img src={item.imageUrl} alt={item.title} className='w-20 h-20' />
                    </div>
                    <div className='flex items-center space-x-4'>
                      <button onClick={() => handleEdit(item._id)} className='text-[#1490C2] underline'>Edit</button>
                      <button onClick={() => handleDelete(item._id)} className='text-[#C41E3A]'>Delete</button>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Categories;
