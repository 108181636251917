import React from 'react';

type LoaderProps = {
  size?: 'large' | 'small';
};

const Loader: React.FC<LoaderProps> = ({ size = 'large' }) => {
  const sizeClasses =
    size === 'large' ? 'w-16 h-16 border-4 border-[#222222]' : 'w-6 h-6 border-2 border-white';

  return (
    <div className={`flex justify-center items-center`}>
      <div
        className={`${sizeClasses} border-t-transparent border-solid rounded-full animate-spin`}
      ></div>
    </div>
  );
};

export default Loader;
