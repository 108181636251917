import React, { useEffect, useState, useContext } from 'react';
import Header from '../../components/Header';
import { toast } from "react-toastify";
import { UserContext } from '../../UserContext';
import Loader from '../../components/Loader';
import { format } from 'date-fns';

interface ContactUsDTO { 
  _id: string;
  name: string;
  email: string;
  message: string;
  dateCreated: string;
}

const ContactUs = () => {
  const [contactItems, setContactItems] = useState<ContactUsDTO[]>([]);
  const [loading, setLoading] = useState(true);
  const { token } = useContext(UserContext);

  const fetchContactItems = async () => {
    try {
      const response = await fetch('https://backend.livecosmetics.pro/api/contactus/getContactUs', {
        headers: {
          'Authorization': `${token}` 
        }
      });
      if (!response.ok) throw new Error('Failed to fetch contact us messages');
      const data = await response.json();
      setContactItems(data);
      setLoading(false);
    } catch (error) {
      toast.error('Failed to load contact us messages');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContactItems();
  }, [token]);

  return (
    <div className='flex flex-col items-center w-full h-full overflow-auto py-10'>
      <Header title={'Contact Us'} button={false} buttonTitle={''} action={''} />
      <div className="w-11/12 rounded-2xl bg-WhiteBg shadow-md pl-12 justify-between pr-12 mt-6 font-poppins">
        <div className='w-full overflow-visible flex-grow'>
          <div className="w-full overflow-visible">
            <div className='w-full grid grid-cols-5 font-bold text-mainTextColor my-4'>
              <div>Name</div>
              <div>Email</div>
              <div>Message</div>
              <div>Date</div>
              <div>Time</div>
            </div>
            <hr className='border-t border-[#cdcdd0]' />
            {
              loading ?
                <div className='items-center justify-center flex w-full h-[200px]'>
                  <Loader size={'large'} />
                </div> :
                contactItems.map((item) => (
                  <div key={item._id} className='w-full grid grid-cols-5 my-4'>
                    <div className='flex items-center'>
                      <p>{item.name}</p>
                    </div>
                    <div className='flex items-center'>
                      <p>{item.email}</p>
                    </div>
                    <div className='flex items-center'>
                      <p>{item.message}</p>
                    </div>
                    <div className='flex items-center'>
                      <p>{format(new Date(item.dateCreated), 'dd/MM/yyyy')}</p>
                    </div>
                    <div className='flex items-center'>
                      <p>{format(new Date(item.dateCreated), 'hh:mm a')}</p> {/* AM/PM format */}
                    </div>
                  </div>
                ))
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
