import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import HeaderWithBack from '../../components/HeaderWithBack';
import { UserContext } from '../../UserContext'; // Assuming you have a UserContext where token is stored
import Loader from '../../components/Loader';

interface Category {
  _id: string;
  title: string;
}

const AddItem: React.FC = () => {
  const { token } = useContext(UserContext);
  const navigate = useNavigate();

  const [productName, setProductName] = useState<string>('');
  const [price, setPrice] = useState<number>();
  const [description, setDescription] = useState<string>('');
  const [methodOfUse, setMethodOfUse] = useState<string>('');
  const [ingredients, setIngredients] = useState<string>('');
  const [quantity, setQuantity] = useState<number>();
  const [images, setImages] = useState<File[]>([]);
  const [parentCategoryId, setParentCategoryId] = useState<string>('');
  const [subCategoryId, setSubCategoryId] = useState<string>('');
  const [childCategoryId, setChildCategoryId] = useState<string>('');
  const [parentCategories, setParentCategories] = useState<Category[]>([]);
  const [subCategories, setSubCategories] = useState<Category[]>([]);
  const [childCategories, setChildCategories] = useState<Category[]>([]);
  const [loadingParentCategories, setLoadingParentCategories] = useState<boolean>(true);
  const [loadingSubCategories, setLoadingSubCategories] = useState<boolean>(false);
  const [loadingChildCategories, setLoadingChildCategories] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  // Fetch parent categories on component mount
  useEffect(() => {
    const fetchParentCategories = async () => {
      setLoadingParentCategories(true);
      try {
        const response = await fetch('https://backend.livecosmetics.pro/api/category/getParentCategories', {
          headers: { 'Authorization': `${token}` }
        });
        if (!response.ok) throw new Error('Failed to fetch parent categories');
        const data = await response.json();
        setParentCategories(data.data);
      } catch (error) {
        toast.error('Failed to fetch parent categories');
      } finally {
        setLoadingParentCategories(false);
      }
    };

    if (token) fetchParentCategories();
  }, [token]);

  // Fetch subcategories when a parent category is selected
  useEffect(() => {
    if (parentCategoryId) {
      const fetchSubCategories = async () => {
        setLoadingSubCategories(true);
        try {
          const response = await fetch(`https://backend.livecosmetics.pro/api/category/getSubCategories/${parentCategoryId}`, {
            headers: { 'Authorization': `${token}` }
          });
          if (!response.ok) throw new Error('Failed to fetch subcategories');
          const data = await response.json();
          setSubCategories(data.data);
        } catch (error) {
          toast.error('Failed to fetch subcategories');
        } finally {
          setLoadingSubCategories(false);
        }
      };

      fetchSubCategories();
    }
  }, [parentCategoryId, token]);

  // Fetch child categories when a subcategory is selected
  useEffect(() => {
    if (subCategoryId) {
      const fetchChildCategories = async () => {
        setLoadingChildCategories(true);
        try {
          const response = await fetch(`https://backend.livecosmetics.pro/api/category/getChildCategories/${subCategoryId}`, {
            headers: { 'Authorization': `${token}` }
          });
          if (!response.ok) throw new Error('Failed to fetch child categories');
          const data = await response.json();
          setChildCategories(data.data);
        } catch (error) {
          toast.error('Failed to fetch child categories');
        } finally {
          setLoadingChildCategories(false);
        }
      };

      fetchChildCategories();
    }
  }, [subCategoryId, token]);

  // Handle image selection
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newImages = Array.from(e.target.files);
      setImages((prevImages) => [...prevImages, ...newImages]);
    }
  };

  const handleDeleteImage = (imageIndex: number) => {
    setImages(images.filter((_, index) => index !== imageIndex));
  };

  // Handle add item
  const handleAddItem = async () => {
    if (!productName || !price || !description || !quantity || !parentCategoryId) {
      toast.error('Please fill in all required fields');
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.set('productName', productName);
    formData.set('price', price.toFixed(2));
    formData.set('description', description);
    if(methodOfUse) { 
      formData.set('methodOfUse', methodOfUse);
    }
    if(ingredients) { 
      formData.set('ingredients', ingredients);
    }
    formData.set('quantity', quantity.toString());
    formData.set('parentCategoryId', parentCategoryId);
    if (subCategoryId) formData.set('subcategoryId', subCategoryId);
    if (childCategoryId) formData.set('childCategoryId', childCategoryId);
    images.forEach((image, index) => {
      formData.append('images', image, `image-${index}`);
    });

    try {
      const response = await fetch('https://backend.livecosmetics.pro/api/product/createProduct', {
        method: 'POST',
        headers: { 'Authorization': `${token}` },
        body: formData
      });

      if (!response.ok) throw new Error('Failed to create product');
      toast.success('Product created successfully!');
      setLoading(false);
      navigate('/products');
    } catch (error) {
      toast.error('Failed to create product');
      setLoading(false);
    }
  };

  return (
    <div className='flex flex-col w-full h-full overflow-auto items-center py-10'>
      <HeaderWithBack previousPage={'Products'} currentPage={'Add Product'} previousPageUrl={'/products'} />
      <div className='bg-WhiteBg shadow-md w-11/12 px-12 py-6 mt-6 rounded-2xl'>
        <input
          type='text'
          placeholder='Product Name'
          value={productName}
          onChange={(e) => setProductName(e.target.value)}
          className='mt-4 p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-gray-300 rounded-md'
        />
        <textarea
        placeholder='Description'
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        className='mt-4 p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-gray-300 rounded-md'
        />
        <textarea
        placeholder='Method of Use (Optional)'
        value={methodOfUse}
        onChange={(e) => setMethodOfUse(e.target.value)}
        className='mt-4 p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-gray-300 rounded-md'
        />
        <textarea
        placeholder='Ingredients (Optional)'
        value={ingredients}
        onChange={(e) => setIngredients(e.target.value)}
        className='mt-4 p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-gray-300 rounded-md'
        />
        <input
          type='number'
          placeholder='Price'
          value={price}
          onChange={(e) => setPrice(parseFloat(e.target.value))}
          className='mt-4 p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-gray-300 rounded-md'
        />
        <input
          type='number'
          placeholder='Quantity'
          value={quantity}
          onChange={(e) => setQuantity(parseInt(e.target.value))}
          className='mt-4 p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-gray-300 rounded-md'
        />
        <div className='flex items-center space-x-4 mt-4'>
          <label htmlFor='product-images' className='bg-blue-500 text-white rounded-md p-2 cursor-pointer'>
            Choose Images
          </label>
          <input type='file' id='product-images' accept='image/*' multiple onChange={handleImageChange} className='hidden' />
          {images.length > 0 && (
            <div className='flex items-center space-x-4'>
              {images.map((image, index) => (
                <div key={index} className='flex items-center'>
                  <img src={URL.createObjectURL(image)} alt='Product' className='w-20 h-20' />
                  <button onClick={() => handleDeleteImage(index)} className='bg-red-500 text-white rounded-md p-2'>
                    Delete
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Category Dropdowns */}
        <div className='mt-4'>
          <label htmlFor='category-select' className='mr-2'>Parent Category:</label>
          <select
            id='category-select'
            value={parentCategoryId}
            onChange={(e) => setParentCategoryId(e.target.value)}
            className='p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-gray-300 rounded-md'
          >
            <option value=''>Select Parent Category</option>
            {parentCategories.map((category) => (
              <option key={category._id} value={category._id}>{category.title}</option>
            ))}
          </select>
        </div>
        {parentCategoryId && (
          <div className='mt-4'>
            <label htmlFor='subcategory-select' className='mr-2'>Subcategory:</label>
            <select
              id='subcategory-select'
              value={subCategoryId}
              onChange={(e) => setSubCategoryId(e.target.value)}
              className='p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-gray-300 rounded-md'
            >
              <option value=''>Select Subcategory</option>
              {subCategories.map((category) => (
                <option key={category._id} value={category._id}>{category.title}</option>
              ))}
            </select>
          </div>
        )}
        {subCategoryId && (
          <div className='mt-4'>
            <label htmlFor='childcategory-select' className='mr-2'>Child Category:</label>
            <select
              id='childcategory-select'
              value={childCategoryId}
              onChange={(e) => setChildCategoryId(e.target.value)}
              className='p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-gray-300 rounded-md'
            >
              <option value=''>Select Child Category</option>
              {childCategories.map((category) => (
                <option key={category._id} value={category._id}>{category.title}</option>
              ))}
            </select>
          </div>
        )}

        <button onClick={handleAddItem} className='bg-blue-500 text-white rounded-md p-2 mt-4 w-full'>
            {
                loading ? 
                <div className='w-full h-full flex items-center justify-center'>
                    <Loader size='small' /> 
                </div> : 
                'Add Product'
            }
        </button>
      </div>
    </div>
  );
};

export default AddItem;
