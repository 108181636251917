import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router';
import HeaderWithBack from '../../components/HeaderWithBack';
import { UserContext } from '../../UserContext';
import Loader from '../../components/Loader';

interface Category {
  _id: string;
  title: string;
}

const EditProduct: React.FC = () => {
  const { token } = useContext(UserContext);
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [productName, setProductName] = useState<string>('');
  const [price, setPrice] = useState<number>();
  const [description, setDescription] = useState<string>('');
  const [methodOfUse, setMethodOfUse] = useState<string>('');
  const [ingredients, setIngredients] = useState<string>('');
  const [quantity, setQuantity] = useState<number>();
  const [images, setImages] = useState<File[]>([]);
  const [existingImages, setExistingImages] = useState<string[]>([]);
  const [imagesToDelete, setImagesToDelete] = useState<string[]>([]);
  const [parentCategoryId, setParentCategoryId] = useState<string>('');
  const [subCategoryId, setSubCategoryId] = useState<string>('');
  const [childCategoryId, setChildCategoryId] = useState<string>('');
  const [parentCategories, setParentCategories] = useState<Category[]>([]);
  const [subCategories, setSubCategories] = useState<Category[]>([]);
  const [childCategories, setChildCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isNew, setIsNew] = useState(false);
  const [itemLoading, setItemLoading] = useState(true);
  const [discount, setDiscount] = useState<number>();
  const [discountEnabled, setDiscountEnabled] = useState(false);

  useEffect(() => {
    const fetchProduct = async () => {
      setItemLoading(true);
      try {
        const response = await fetch(`https://backend.livecosmetics.pro/api/product/${id}`, {
          headers: { 'Authorization': `${token}` },
        });

        if (!response.ok) throw new Error('Failed to fetch product data');
        const product = await response.json();

        setProductName(product.productName || '');
        setPrice(product.price || 0);
        setDescription(product.description || '');
        setMethodOfUse(product.methodOfUse || '');
        setIngredients(product.ingredients || '');
        setQuantity(product.quantity || 0);
        setParentCategoryId(product.parentCategoryId._id || '');
        setSubCategoryId(product.subcategoryId._id || '');
        setChildCategoryId(product.childCategoryId._id || '');
        console.log(product.imageUrls)
        setExistingImages(product.imageUrls || []);
        setIsNew(product.isNew);
        setDiscount(product.discount.newPrice);
        setDiscountEnabled(product.discount.enabled);
      } catch (error) {
        toast.error('Failed to fetch product data');
      } finally {
        setItemLoading(false);
      }
    };

    fetchProduct();
  }, [id, token]);

  useEffect(() => {
    const fetchParentCategories = async () => {
      try {
        const response = await fetch('https://backend.livecosmetics.pro/api/category/getParentCategories', {
          headers: { 'Authorization': `${token}` },
        });
        const data = await response.json();
        setParentCategories(data.data || []);
      } catch (error) {
        toast.error('Failed to fetch parent categories');
      }
    };

    fetchParentCategories();
  }, [token]);

  useEffect(() => {
    if (parentCategoryId) {
      const fetchSubCategories = async () => {
        try {
          const response = await fetch(`https://backend.livecosmetics.pro/api/category/getSubCategories/${parentCategoryId}`, {
            headers: { 'Authorization': `${token}` },
          });
          const data = await response.json();
          setSubCategories(data.data || []);
        } catch (error) {
          toast.error('Failed to fetch subcategories');
        }
      };

      fetchSubCategories();
    }
  }, [parentCategoryId, token]);

  useEffect(() => {
    if (subCategoryId) {
      const fetchChildCategories = async () => {
        try {
          const response = await fetch(`https://backend.livecosmetics.pro/api/category/getChildCategories/${subCategoryId}`, {
            headers: { 'Authorization': `${token}` },
          });
          const data = await response.json();
          setChildCategories(data.data || []);
        } catch (error) {
          toast.error('Failed to fetch child categories');
        }
      };

      fetchChildCategories();
    }
  }, [subCategoryId, token]);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newImages = Array.from(e.target.files);
      setImages((prevImages) => [...prevImages, ...newImages]);
    }
  };

  const handleDeleteExistingImage = (imageUrl: string) => {
    setImagesToDelete((prev) => [...prev, imageUrl]);
    setExistingImages(existingImages.filter((image) => image !== imageUrl));
  };

  const handleDeleteNewImage = (imageIndex: number) => {
    setImages(images.filter((_, index) => index !== imageIndex));
  };

  const handleUpdateItem = async () => {
    if (!productName || !price || !description || !quantity || !parentCategoryId) {
      toast.error('Please fill in all required fields');
      return;
    }
  
    setLoading(true);
  
    const formData = new FormData();
    formData.set('productName', productName);
    formData.set('price', price.toFixed(2));
    formData.set('description', description);
    formData.set('methodOfUse', methodOfUse);
    formData.set('ingredients', ingredients);
    formData.set('quantity', quantity.toString());
    formData.set('parentCategoryId', parentCategoryId);
  
    // Convert array of existing images to comma-separated string
    console.log('test', existingImages)
    // formData.set('imageUrls', existingImages.join(','));

    existingImages.forEach((url, index) => {
      formData.append(`imageUrls[${index}]`, url);
    });
  
    formData.set('isNew', isNew.toString());
    formData.set('discount.enabled', discountEnabled.toString());
    
    if(discount) { 
      formData.set('discount.newPrice', discount.toString());
    }
  
    if (subCategoryId) formData.set('subcategoryId', subCategoryId);
    if (childCategoryId) formData.set('childCategoryId', childCategoryId);
  
    images.forEach((image, index) => {
      formData.append('images', image, `image-${index}`);
    });
  
    formData.forEach((value, key) => {
      console.log(`${key}: ${value}`);
    });

    try {
      const response = await fetch(`https://backend.livecosmetics.pro/api/product/${id}`, {
        method: 'PUT',
        headers: { 'Authorization': `${token}` },
        body: formData,
      });
  
      if (!response.ok) throw new Error('Failed to update product');
      toast.success('Product updated successfully!');
      navigate('/products');
    } catch (error) {
      toast.error('Failed to update product');
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div className='flex flex-col w-full h-full overflow-auto items-center py-10'>
      <HeaderWithBack previousPage={'Products'} currentPage={'Edit Product'} previousPageUrl={'/products'} />
      <div className='bg-WhiteBg shadow-md w-11/12 px-12 py-6 mt-6 rounded-2xl'>
        {itemLoading ? (
          <Loader size='large' />
        ) : (
          <>
            <input
              type='text'
              placeholder='Product Name'
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              className='mt-4 p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-gray-300 rounded-md'
            />
            <textarea
              placeholder='Description'
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className='mt-4 p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-gray-300 rounded-md'
            />
            <textarea
              placeholder='Method of Use'
              value={methodOfUse}
              onChange={(e) => setMethodOfUse(e.target.value)}
              className='mt-4 p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-gray-300 rounded-md'
            />
            <textarea
              placeholder='Ingredients'
              value={ingredients}
              onChange={(e) => setIngredients(e.target.value)}
              className='mt-4 p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-gray-300 rounded-md'
            />
            <input
              type='number'
              placeholder='Price'
              value={price}
              onChange={(e) => setPrice(parseFloat(e.target.value))}
              className='mt-4 p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-gray-300 rounded-md'
            />
            <input
              type='number'
              placeholder='Quantity'
              value={quantity}
              onChange={(e) => setQuantity(parseInt(e.target.value))}
              className='mt-4 p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-gray-300 rounded-md'
            />


            <label>
              <input
                type="checkbox"
                checked={discountEnabled}
                onChange={() => {
                  setDiscountEnabled(!discountEnabled);
                }}
                className='mr-2 mt-5'
              />
              Discount
            </label>
            {
              discountEnabled && ( 
                <input
                  type='discount'
                  placeholder='Discount'
                  value={discount}
                  onChange={(e) => setDiscount(parseInt(e.target.value))}
                  className='mt-4 p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-gray-300 rounded-md'
                />
              )
            }
            <div className='flex items-center space-x-4 mt-4'>
              <label htmlFor='product-images' className='bg-blue-500 text-white rounded-md p-2 cursor-pointer'>
                Choose Images
              </label>
              <input type='file' id='product-images' accept='image/*' multiple onChange={handleImageChange} className='hidden' />
              {images.length > 0 && (
                <div className='flex items-center space-x-4'>
                  {images.map((image, index) => (
                    <div key={index} className='flex items-center'>
                      <img src={URL.createObjectURL(image)} alt='Product' className='w-20 h-20' />
                      <button onClick={() => handleDeleteNewImage(index)} className='bg-red-500 text-white rounded-md p-2'>
                        Delete
                      </button>
                    </div>
                  ))}
                </div>
              )}
              {existingImages.length > 0 && (
                <div className='flex items-center space-x-4'>
                  {existingImages.map((imageUrl, index) => (
                    <div key={index} className='flex items-center'>
                      <img src={imageUrl} alt='Existing Product' className='w-20 h-20' />
                      <button onClick={() => handleDeleteExistingImage(imageUrl)} className='bg-red-500 text-white rounded-md p-2'>
                        Delete
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <label>
              <input
                type="checkbox"
                checked={isNew}
                onChange={() => {
                  setIsNew(!isNew);
                }}
                className='mr-2 mt-5'
              />
              Is New
            </label>


            {/* Category Dropdowns */}
            <div className='mt-4'>
              <label htmlFor='category-select' className='mr-2'>Parent Category:</label>
              <select
                id='category-select'
                value={parentCategoryId}
                onChange={(e) => setParentCategoryId(e.target.value)}
                className='p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-gray-300 rounded-md'
              >
                <option value=''>Select Parent Category</option>
                {parentCategories.length > 0 && parentCategories.map((category) => (
                  <option key={category._id} value={category._id}>{category.title}</option>
                ))}
              </select>
            </div>
            {parentCategoryId && (
              <div className='mt-4'>
                <label htmlFor='subcategory-select' className='mr-2'>Subcategory:</label>
                <select
                  id='subcategory-select'
                  value={subCategoryId}
                  onChange={(e) => setSubCategoryId(e.target.value)}
                  className='p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-gray-300 rounded-md'
                >
                  <option value=''>Select Subcategory</option>
                  {subCategories.length > 0 && subCategories.map((category) => (
                    <option key={category._id} value={category._id}>{category.title}</option>
                  ))}
                </select>
              </div>
            )}
            {subCategoryId && (
              <div className='mt-4'>
                <label htmlFor='childcategory-select' className='mr-2'>Child Category:</label>
                <select
                  id='childcategory-select'
                  value={childCategoryId}
                  onChange={(e) => setChildCategoryId(e.target.value)}
                  className='p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-gray-300 rounded-md'
                >
                  <option value=''>Select Child Category</option>
                  {childCategories.length > 0 && childCategories.map((category) => (
                    <option key={category._id} value={category._id}>{category.title}</option>
                  ))}
                </select>
              </div>
            )}

            <button onClick={handleUpdateItem} className='bg-blue-500 text-white rounded-md p-2 mt-4 w-full'>
              {loading ? (
                <div className='w-full h-full flex items-center justify-center'>
                  <Loader size='small' />
                </div>
              ) : (
                'Update Product'
              )}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default EditProduct;
