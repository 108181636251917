import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';


import { Link } from 'react-router-dom';
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import Header from '../../components/Header';
import Search from '../../components/Search';
import { UserContext } from '../../UserContext';
import Loader from '../../components/Loader';

type Props = {};

const Products: React.FC<Props> = () => {
  const navigate = useNavigate();

  const [search, setSearch] = useState<string>('');
  const [items, setItems] = useState<any>([]);
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(1);
  const observer = useRef<IntersectionObserver | null>(null);
  const { token } = useContext(UserContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchItems(true)
  }, [search]);

  useEffect(() => {
    if (page !== 1) {
      fetchItems(false)
    }
  }, [page]);

  const fetchItems = async (reset: boolean = false) => {
    try {
      let fetchUrl = `https://backend.livecosmetics.pro/api/product/search?productName=${search}&limit=10`;

      if (reset) {
        fetchUrl += `&page=1`
      } else {
        fetchUrl += `&page=${page}`
      }

      const response = await fetch(fetchUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`,
        },
      });
      const data = await response.json();
      console.log(data.products)

      if (data.products) {
        setItems((prevItems: any) => (reset ? data.products : [...prevItems, ...data.products]));
        setTotalPages(data.totalPages);
      }


      if (reset) {
        setPage(1)
      }

      setLoading(false);
    } catch (error) {
      console.error('Error fetching items:', error);
      setLoading(false);
    }
  };

  const lastItemRef = useCallback((node: HTMLDivElement | null) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && page < totalPages) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [page, totalPages]);

  const handleEdit = (itemId: any) => {
    navigate(`/products/edit/${itemId}`);
  };

  const handleDelete = async (itemId: any) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this item?');
    if (confirmDelete) {
      try {
        let fetchUrl = `https://backend.livecosmetics.pro/api/product/${itemId}/deleteProduct`;
        const response = await fetch(fetchUrl, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`,
          },
        });

        setPage(1)
        setItems([])
        fetchItems(true);
      } catch (error) {
        toast.error((error as Error).message);
      }
    }
  };

  return (
    <div className='flex flex-col items-center w-full h-full overflow-auto py-10'>
      <Header title={'Products'} button={true} buttonTitle={'Add product'} action={'/products/add'} />
      <div className="w-11/12 rounded-2xl bg-WhiteBg shadow-md pl-12 justify-between pr-12 mt-6 font-poppins">
        <Search search={search} setSearch={setSearch} placeholderText={'Item'} />
        <div className='w-full overflow-visible flex-grow'>
          <div className="w-full overflow-visible">
            <div className='w-full grid grid-cols-4 font-bold text-mainTextColor my-4'>
              <div>Name</div>
              <div>Price</div>
              <div>Image</div>
              <div>Actions</div>
            </div>
            <hr className='border-t border-[#cdcdd0]' />
            {
              loading ? 
              <div className='items-center justify-center flex w-full h-[200px] bg-red-500'>
                <Loader size={'large'}/>
                </div> : ( 
                  items.map((item: any, index: any) => (
                    <div
                      key={item.id}
                      className='w-full grid grid-cols-4 my-4'
                      ref={index === items.length - 1 ? lastItemRef : null}
                    >
                      <div className='text-TextBlue flex items-center'>
                        <p className='underline'>{item.productName}</p>
                      </div>
                      <div className='text-TextBlue flex items-center'>
                        <p className=''>${item.price}</p>
                      </div>
                      <div className='flex'>
                        <img src={item.imageUrls[0]} alt={item.productName} className='w-20 h-20' />
                      </div>
                      <div className='flex items-center space-x-4'>
                        <button onClick={() => handleEdit(item._id)}
                          className='text-[#1490C2] underline'>Edit
                        </button>
                        <button onClick={() => handleDelete(item._id)} className='text-[#C41E3A]'>Delete
                        </button>
                      </div>
                    </div>
                  ))
                )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
