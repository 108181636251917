import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Header from '../../components/Header';
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import Loader from '../../components/Loader';
import { UserContext } from '../../UserContext';

const Orders = () => {
  const [orders, setOrders] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [size] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [status, setStatus] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const { token } = useContext(UserContext);

  const navigate = useNavigate();
  const observer = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    fetchOrders(true);
  }, [status]);

  useEffect(() => {
    if (page !== 1) {
      fetchOrders(false);
    }
  }, [page]);

  const fetchOrders = async (reset: boolean = false) => {
    setLoading(true);
    try {
      let fetchUrl = `https://backend.livecosmetics.pro/api/orders/getAllOrders?page=${reset ? 1 : page}&limit=${size}`;
      if (status) {
        fetchUrl += `&status=${status}`;
      }

      const response = await fetch(fetchUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch orders');
      }

      const data = await response.json();

      setOrders(prevOrders => (reset ? data.orders : [...prevOrders, ...data.orders]));
      setTotalPages(data.totalPages);

      if (reset) {
        setPage(1);
      }
    } catch (error) {
      toast.error('Error fetching orders');
    } finally {
      setLoading(false);
    }
  };

  const lastOrderRef = useCallback((node: HTMLDivElement | null) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && page < totalPages) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [page, totalPages]);

  const handleStatusChange = async (orderId: string, newState: string) => {
    try {
      const response = await fetch(`https://backend.livecosmetics.pro/api/orders/${orderId}/status`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify({ status: newState })
      });

      if (!response.ok) {
        throw new Error('Failed to update order status');
      }

      setOrders((prevOrders) => prevOrders.map(order =>
        order._id === orderId ? { ...order, status: newState } : order
      ));

      toast.success("Order status updated successfully!");
    } catch (error) {
      toast.error((error as Error).message);
    }
  };


  const orderStatuses = [
    'pending',
    'confirmed',
    'processing',
    'picked',
    'shipped',
    'delivered',
    'waiting for payment',
    'canceled'
  ];

  return (
    <div className='flex flex-col items-center w-full h-full overflow-auto py-10'>
      <Header title={'Orders'} button={false} buttonTitle={''} action={''} />

      <div className="w-11/12 rounded-2xl bg-WhiteBg shadow-md pl-12 justify-between pr-12 mt-6 font-poppins">
        {/* Order status filter */}
        <div className='my-4'>
          <select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className='p-2 border rounded'
          >
            <option value="">All Order States</option>
            {orderStatuses.map((status, index) => (
              <option key={index} value={status}>
                {status.charAt(0).toUpperCase() + status.slice(1)}
              </option>
            ))}
          </select>
        </div>

        <div className='w-full overflow-visible flex-grow'>
          <div className="w-full overflow-visible">
            <div className='w-full grid grid-cols-7 font-bold text-mainTextColor my-4'>
              <div>Order #</div>
              <div>Order Date</div>
              <div className="ml-14">Order Status</div>
              <div className='ml-10'>Customer</div>
              <div className='ml-10'>Phone Number</div>
              <div className='ml-10'>Order Total</div>
              <div className='ml-10'>Actions</div>
            </div>
            <hr className='border-t border-[#cdcdd0]' />

            {orders.map((order, index) => (
              <div
                key={order._id}
                className={`w-full grid grid-cols-7 my-4`}
                ref={index === orders.length - 1 ? lastOrderRef : null}
              >
                <div className='flex items-center'>
                  <p className=''>{order.orderNumber}</p>
                </div>
                <div className='text-TextBlue flex items-center'>
                  <p className='underline'>
                    {new Date(order.dateCreated).toLocaleString('en-US', {
                      hour: '2-digit',
                      minute: '2-digit',
                      second: '2-digit',
                      year: 'numeric',
                      month: 'long',
                      day: '2-digit'
                    })}
                  </p>
                </div>
                <div className='ml-14 text-TextBlue flex items-center'>
                  <select
                    value={order.status}
                    onChange={(e) => handleStatusChange(order._id, e.target.value)}
                    className={`rounded-md border border-black p-1 w-[100px]`}
                  >
                    {orderStatuses.map((status, index) => (
                      <option key={index} value={status}>
                        {status.charAt(0).toUpperCase() + status.slice(1)}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='text-TextBlue flex items-center ml-10'>
                  <p className=''>{order.customerName}</p>
                </div>
                <div className='flex items-center ml-10'>
                  <p className=''>{order.phoneNumber|| 'N/A'}</p>
                </div>
                <div className='flex items-center ml-10'>
                  <p className=''>${order.totalPrice}</p>
                </div>
                <div className='flex items-center space-x-4 ml-10'>
                  <Link to={`/orders/view/${order._id}`} className='text-[#1490C2] underline'>
                    View Details
                  </Link>
                </div>
              </div>
            ))}

            {loading && (
              <div className='items-center justify-center flex w-full h-[200px]'>
                <Loader size={'large'} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;
