import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Login from "./pages/Login";
import { useState, useEffect, useContext } from "react";
import Navbar from "./components/Navbar";
import Orders from "./pages/NavBar/Orders";
import Products from "./pages/NavBar/Products";
import Categories from "./pages/NavBar/Categories";
import Carousel from "./pages/NavBar/Carousel";
import { UserContext } from "./UserContext";
import { onAuthStateChanged, getIdToken, signOut } from "firebase/auth";
import { auth } from "../src/firebase/firebaseConfig";
import 'react-toastify/dist/ReactToastify.css';
import Loader from "./components/Loader";
import AddCategory from "./pages/Categories/AddCategories";
import EditCategory from "./pages/Categories/EditCategories";
import AddCarousel from "./pages/Carousel/AddCarousel";
import OrderDetails from "./pages/Orders/OrderDetails";
import Users from "./pages/NavBar/Users";
import ContactUs from "./pages/NavBar/ContactUs";
import AddItem from "./pages/Items/AddItems";
import EditProduct from "./pages/Items/EditItem";
import Discount from "./pages/NavBar/Discount";

function App() {
  const [userData, setUserData] = useState<any>(null);
  const [token, setToken] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const idToken = await getIdToken(user);
          fetchUserData(idToken);
          setToken(idToken);
        } catch (error) {
          toast.error("Failed to retrieve token.");
          console.error("Error fetching ID token:", error);
        }
      } else {
        setLoading(false);
        setUserData(null);
        setToken(null);
      }
    });

    return () => unsubscribe(); 
  }, []);

  const fetchUserData = async (token: string) => {
    try {
        const response = await fetch(`https://backend.livecosmetics.pro/api/user/getInfo/uid`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `${token}`,  
            },
        });

        if (!response.ok) {
            throw new Error('Failed to fetch user data');
        }

        const data = await response.json();
        if (data.user.role === 'admin') {
          setUserData(data);
          setToken(token);
          setLoading(false)
        } else {
          await signOut(auth); 
        }
    } catch (error) {
        console.error("Error fetching user data:", error);
    }
} ;
  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (auth.currentUser) {
        try {
          const idToken = await getIdToken(auth.currentUser, true);
          setToken(idToken);
        } catch (error) {
          toast.error("Error refreshing token.");
        }
      }
    }, 60 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  const AppRoutes: React.FC = () => {
    const navigate = useNavigate();
    return (
      
        loading ? (
          <div className="h-screen w-screen flex items-center justify-center">
            <Loader size="large"/>
          </div>
        ) : ( 
          <>
            <div className='flex font-font400'>
              {userData ? (
                <>
                  <Navbar />
                  <div className="h-screen w-screen bg-SecondMainBgColor">
                    <div className={`h-screen w-full pl-72 flex flex-col`}>
                      <Routes>
                        <Route path="/" element={<Orders />} />
                        <Route path="/orders" element={<Orders />} />
                        <Route path="/users" element={<Users />} />
                        <Route path="/products" element={<Products />} />
                        <Route path="/products/add" element={<AddItem />} />
                        <Route path="/products/edit/:id" element={<EditProduct />} />
                        <Route path="/categories" element={<Categories />} />
                        <Route path="/categories/add" element={<AddCategory />} />
                        <Route path="/categories/edit/:id" element={<EditCategory />} />
                        <Route path="/carousel" element={<Carousel />} />
                        <Route path="/carousel/add" element={<AddCarousel />} />
                        <Route path="/orders/view/:id" element={<OrderDetails />} />
                        <Route path="/contact-us" element={<ContactUs />} />
                        <Route path="/discount" element={<Discount />} />
                      </Routes>
                    </div>
                  </div>
                </>
              ) : (
                <Login />
              )}
            </div>
          </>
        )
      
    );
  };

  return (
    <UserContext.Provider value={{ userData, setUserData, token, setToken }}>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
      <ToastContainer />
    </UserContext.Provider>
  );
}

export default App;
