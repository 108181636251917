import React, { useEffect, useState, useContext } from 'react';
import HeaderWithBack from '../../components/HeaderWithBack';
import { useNavigate, useParams } from 'react-router';
import { toast } from "react-toastify";
import { UserContext } from '../../UserContext'; // Assuming token is in UserContext
import Loader from '../../components/Loader';

const EditCategory: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { token } = useContext(UserContext); // Get the token from the UserContext

  const [categoryName, setCategoryName] = useState<string>('');
  const [categoryImage, setCategoryImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string>('');
  const [loading, setLoading] = useState(true);

  // Fetch category by ID
  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const response = await fetch(`https://backend.livecosmetics.pro/api/category/${id}`, {
          headers: {
            'Authorization': `${token}`,
            'Content-Type': 'application/json'
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch category');
        }
        const data = await response.json();
        setCategoryName(data.title); // Assuming title corresponds to name
        setImagePreview(data.imageUrl); // Assuming imageUrl corresponds to image
        setLoading(false);
      } catch (error) {
        toast.error((error as Error).message);
        setLoading(false);
      }
    };

    fetchCategory();
  }, [id, token]);

  // Handle image change
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setCategoryImage(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  // Handle image delete
  const handleDeleteImage = () => {
    setCategoryImage(null);
    setImagePreview('');
  };

  // Handle category update (PUT request)
  const handleSaveCategory = async () => {
    if (categoryName === '' || (!categoryImage && !imagePreview)) {
      toast.error("Please fill in all required fields before saving the category");
      return;
    }

    const formData = new FormData();
    formData.set("title", categoryName); // Assuming "title" is the name field

    if (categoryImage) {
      formData.set("image", categoryImage, categoryImage.name);
    }

    try {
      const response = await fetch(`https://backend.livecosmetics.pro/api/category/${id}`, {
        method: 'PUT',
        body: formData,
        headers: {
          'Authorization': `${token}`
        }
      });

      if (!response.ok) {
        throw new Error("Failed to update category");
      }

      toast.success("Category updated successfully!");
      navigate('/categories'); // Redirect to categories list after successful update
    } catch (e) {
      toast.error((e as Error).message);
    }
  };

  return (
    <div className='flex flex-col w-full h-full overflow-auto items-center py-10'>
      <HeaderWithBack previousPage={'Categories'} currentPage={'Edit Category'} previousPageUrl={'/categories'} />
      <div className='bg-WhiteBg shadow-md w-11/12 px-12 py-6 mt-6 rounded-2xl'>
      {
        loading ? ( 
            <div className='items-center justify-center flex'>
                <Loader size='large'/>
            </div>
        ) : ( 
            <>
                <div className='flex items-center space-x-4'>
                <label htmlFor='category-image' className='bg-[#1490C2] text-white rounded-md p-2 cursor-pointer'>
                    Choose Image
                </label>
                <input
                    type='file'
                    id='category-image'
                    accept='image/*'
                    onChange={handleImageChange}
                    className='hidden'
                />
                {imagePreview && (
                    <div className='flex items-center space-x-4'>
                    <img src={imagePreview} alt='Category' className='w-20 h-20' />
                    <button onClick={handleDeleteImage} className='bg-[#C41E3A] text-white rounded-md p-2'>
                        Delete Image
                    </button>
                    </div>
                )}
                </div>
                <input
                type='text'
                placeholder='Category Name'
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
                className='mt-4 p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-primary rounded-md'
                />
                <button onClick={handleSaveCategory} className='bg-[#1490C2] text-white rounded-md p-2 mt-4 w-full'>
                Save Category
                </button>
            </>
        )
      }
      </div>
    </div>
  );
};

export default EditCategory;
