import React, { useContext } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { signOut } from "firebase/auth";
import { auth } from '../firebase/firebaseConfig'; 
import { UserContext } from '../UserContext';

type Props = {}

const Navbar = (props: Props) => {

  const { setToken, setUserData } = useContext(UserContext);
  const navigate = useNavigate();

  const handleLogout = async () => {
    if (window.confirm("Are you sure you want to log out?")) {
      try {
        await signOut(auth);
        setUserData(null);
        setToken(null);
        navigate('/login');
      } catch (error) {
        console.error("Error logging out:", error);
      }
    }
  };
  

  return (
    <div className={`h-screen fixed z-10 bg-navbarColor top-0 left-0 py-4 pr-1 m-0 flex flex-col justify-between shadow-sm w-72`}>
      <div>
        <ul>
          <li className={`flex mb-8`}>
            <button
              className='text-white px-3 py-3 pl-16 min-w-full w-3/4 flex items-center text-lg'
            >
              <p className='font-medium font-poppins'>Live Cosmetics Console</p>
            </button>
          </li>
          <li className={`mb-1`}>
            <NavLink
              to="/products"
              className={({ isActive }) =>
                isActive
                  ? `text-white py-3 px-3 pl-16 bg-SelectedBarText w-3/4 flex rounded-r-full items-center`
                  : `text-white px-3 py-3 pl-16 w-3/4 flex items-center`
              }
            >
              <p className='font-poppins'>Products</p>
            </NavLink>
          </li>
          <li className={`mb-1`}>
            <NavLink
              to="/users"
              className={({ isActive }) =>
                isActive
                  ? `text-white py-3 px-3 pl-16 bg-SelectedBarText w-3/4 flex rounded-r-full items-center`
                  : `text-white px-3 py-3 pl-16 w-3/4 flex items-center`
              }
            >
              <p className='font-poppins'>Users</p>
            </NavLink>
          </li>
          <li className={`mb-1`}>
            <NavLink
              to="/categories"
              className={({ isActive }) =>
                isActive
                  ? `text-white py-3 px-3 pl-16 bg-SelectedBarText w-3/4 flex rounded-r-full items-center`
                  : `text-white px-3 py-3 pl-16 w-3/4 flex items-center`
              }
            >
              <p className='font-poppins'>Categories</p>
            </NavLink>
          </li>
          <li className={`mb-1`}>
            <NavLink
              to="/carousel"
              className={({ isActive }) =>
                isActive
                  ? `text-white py-3 px-3 pl-16 bg-SelectedBarText w-3/4 flex rounded-r-full items-center`
                  : `text-white px-3 py-3 pl-16 w-3/4 flex items-center`
              }
            >
              <p className='font-poppins'>Carousel</p>
            </NavLink>
          </li>
          <li className={`mb-1`}>
            <NavLink
              to="/orders"
              className={({ isActive }) =>
                isActive
                  ? `text-white py-3 px-3 pl-16 bg-SelectedBarText w-3/4 flex rounded-r-full items-center`
                  : `text-white px-3 py-3 pl-16 w-3/4 flex items-center`
              }
            >
              <p className='font-poppins'>Orders</p>
            </NavLink>
          </li>
          <li className={`mb-1`}>
            <NavLink
              to="/contact-us"
              className={({ isActive }) =>
                isActive
                  ? `text-white py-3 px-3 pl-16 bg-SelectedBarText w-3/4 flex rounded-r-full items-center`
                  : `text-white px-3 py-3 pl-16 w-3/4 flex items-center`
              }
            >
              <p className='font-poppins'>Contact Us</p>
            </NavLink>
          </li>
          <li className={`mb-1`}>
            <NavLink
              to="/discount"
              className={({ isActive }) =>
                isActive
                  ? `text-white py-3 px-3 pl-16 bg-SelectedBarText w-3/4 flex rounded-r-full items-center`
                  : `text-white px-3 py-3 pl-16 w-3/4 flex items-center`
              }
            >
              <p className='font-poppins'>Discount</p>
            </NavLink>
          </li>
          <li className={`mb-1`}>
            <button
              className={`text-white py-3 px-3 pl-16 hover:bg-SelectedBarText w-3/4 flex rounded-r-full items-center`}
              onClick={() => handleLogout()}
            >
              <p className='font-poppins'>Logout</p>
            </button>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Navbar
