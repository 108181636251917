import React from "react";

interface IUserContext {
    userData: any;
    setUserData: (entity: any) => void;
    token: any;
    setToken: (entity: any) => void;  
}

const defaultState = {
    userData: null,
    token: null,
    setToken: () => { },
    setUserData: () => { }
};

export const UserContext = React.createContext<IUserContext>(defaultState);