import React from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as BackArrow } from '../Images/BackArrow.svg';

type Props = {
    previousPage: string,
    currentPage: string,
    previousPageUrl: string
}

const HeaderWithBack = ({previousPage, currentPage, previousPageUrl}: Props) => {
  return (
    <div className="w-11/12 rounded-2xl bg-WhiteBg shadow-md flex items-center pl-12 justify-between pr-12">
        <div className='flex items-center h-20'>
            <Link to={`${previousPageUrl}`} className='flex items-center'>
                <BackArrow className='mr-5' />
                <p className='font-bold text-lg text-mainTextColor mr-2 underline'>
                    {previousPage}
                </p>
            </Link>

            <p className='font-bold text-lg text-mainTextColor mr-2'>
                &gt;
            </p>
            <p className='text-md text-mainTextColor font-semibold'>{currentPage}</p>
        </div>
    </div>
  )
}

export default HeaderWithBack