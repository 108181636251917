import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router';
import { toast } from "react-toastify";
import HeaderWithBack from '../../components/HeaderWithBack';
import { UserContext } from '../../UserContext'; // Assuming token comes from here
import Loader from '../../components/Loader';

const AddCarousel: React.FC = () => {
  const navigate = useNavigate();
  const { token } = useContext(UserContext); // Get the token from UserContext

  const [carouselType, setCarouselType] = useState<string>('image'); // Dropdown value for type
  const [file, setFile] = useState<File | null>(null); // File upload
  const [filePreview, setFilePreview] = useState<string>(''); // File preview
  const [loading, setLoading] = useState(false);

  const handleTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCarouselType(e.target.value);
    setFile(null); // Reset file when type changes
    setFilePreview('');
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      setFile(selectedFile);
      setFilePreview(URL.createObjectURL(selectedFile)); // Show a preview of the selected file
    }
  };

  const handleDeleteFile = () => {
    setFile(null);
    setFilePreview('');
  };

  const handleAddCarousel = async () => {
    if (!carouselType || !file) {
      toast.error("Please select a type and upload a file");
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.set("type", carouselType);
    formData.set("image", file, file.name);

    try {
      const response = await fetch(`https://backend.livecosmetics.pro/api/carousel/createCarousel`, {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `${token}`
        }
      });

      if (!response.ok) {
        throw new Error("Failed to create carousel item");
      }

      toast.success("Carousel item created successfully!");
      navigate('/carousel');
      setLoading(false);
    } catch (e) {
        setLoading(false);
        toast.error((e as Error).message);
    }
  };

  return (
    <div className='flex flex-col w-full h-full overflow-auto items-center py-10'>
      <HeaderWithBack previousPage={'Carousel'} currentPage={'Add Carousel'} previousPageUrl={'/carousel'} />
      <div className='bg-WhiteBg shadow-md w-11/12 px-12 py-6 mt-6 rounded-2xl'>
        <div className='flex items-center space-x-4'>
          <label htmlFor='carousel-type' className='text-mainTextColor font-bold'>Select Type</label>
          <select
            id='carousel-type'
            value={carouselType}
            onChange={handleTypeChange}
            className='mt-1 p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-primary rounded-md w-1/4'
          >
            <option value='image'>Image</option>
            <option value='video'>Video</option>
          </select>
        </div>

        <div className='flex items-center space-x-4 mt-4'>
          <label htmlFor='carousel-file' className='bg-[#1490C2] text-white rounded-md p-2 cursor-pointer'>
            {carouselType === 'image' ? 'Choose Image' : 'Choose Video'}
          </label>
          <input
            type='file'
            id='carousel-file'
            accept={carouselType === 'image' ? 'image/*' : 'video/*'}
            onChange={handleFileChange}
            className='hidden'
            disabled={!carouselType} // Disable file input if type is not selected
          />
          {filePreview && (
            <div className='flex items-center space-x-4'>
              {carouselType === 'image' ? (
                <img src={filePreview} alt='Preview' className='w-20 h-20' />
              ) : (
                <video src={filePreview} className='w-20 h-20' muted autoPlay loop playsInline />
              )}
              <button onClick={handleDeleteFile} className='bg-[#C41E3A] text-white rounded-md p-2'>
                Delete {carouselType === 'image' ? 'Image' : 'Video'}
              </button>
            </div>
          )}
        </div>

        <button
          onClick={handleAddCarousel}
          className='bg-[#1490C2] text-white rounded-md p-2 mt-4 w-full'
          disabled={!file || !carouselType} // Disable if no file or type is selected
        >
            {
                loading ? 
                <div className='w-full h-full items-center justify-center flex'>
                    <Loader size='small'/>
                </div> : 
                'Add Carousel Item'
            }
        </button>
      </div>
    </div>
  );
};

export default AddCarousel;
