import React, { useEffect, useRef, useState, useContext } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import HeaderWithBack from '../../components/HeaderWithBack';
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { UserContext } from '../../UserContext'; // Assuming you have a UserContext for token
import Loader from '../../components/Loader';

const OrderDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { token } = useContext(UserContext); // Get the token from UserContext

  const [order, setOrder] = useState<any>(null);
  const printRef = useRef(null);

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const response = await fetch(`https://backend.livecosmetics.pro/api/orders/getOrder/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}` // Include the token in the request headers
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch order details');
        }

        const data = await response.json();
        setOrder(data); // Set the order data
      } catch (error) {
        toast.error((error as Error).message);
      }
    };

    fetchOrder();
  }, [id, token]);

  const handleStateChange = async (newState: string) => {
    try {
      if (order) {
        // Update order status in the backend
        const response = await fetch(`https://backend.livecosmetics.pro/api/orders/${order._id}/status`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`,
          },
          body: JSON.stringify({ status: newState })
        });

        if (!response.ok) {
          throw new Error('Failed to update order status');
        }

        console.log(order)
        // Update order state in the frontend
        setOrder({
          ...order,
          status: newState
        });
        
        toast.success('Order status updated successfully!');
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  const orderStatuses = [
    'pending',
    'confirmed',
    'processing',
    'picked',
    'shipped',
    'delivered',
    'canceled'
  ];

  return (
    <div className='flex flex-col w-full h-full overflow-auto items-center py-10'>
      <HeaderWithBack previousPage={'Orders'} currentPage={'Order Details'} previousPageUrl={'/orders'} />
      <div className='bg-whiteBg shadow-md w-11/12 px-12 py-6 mt-6 rounded-2xl'>
        {order ? (
          <>
            {/* Order Information */}
            <div className='mb-6'>
              <h2 className='text-xl font-bold mb-5'>Order Details</h2>
              <p><strong>Order Number:</strong> {order.orderNumber}</p>
              <p><strong>Customer Name:</strong> {order.customerName}</p>
              <p><strong>Email:</strong> {order.email || 'Not provided'}</p>
              <p><strong>Phone Number:</strong> {order.phoneNumber || 'Not provided'}</p>
            </div>

            {/* Order Items */}
            <div className='mb-6'>
              <h3 className='text-lg font-bold mb-5'>Items</h3>
              {order.items.map((item: any) => (
                <div key={item._id} className='mb-4 flex items-center'>
                  <img
                    src={item.productId.imageUrls[0]} 
                    alt={item.productId.productName}
                    className='w-20 h-20 rounded-md mb-2'
                  />
                  <div className='ml-5'>
                    <p><strong>{item.productId.productName}</strong></p>
                    <p><strong>Quantity:</strong> {item.quantity}</p>
                  </div>
                </div>
              ))}
               <div className='mb-6'>
                <p><strong>Order Notes:</strong> {order.orderNotes}</p>
              </div>
            </div>

            {/* Shipping & Billing Information */}
            <div className='mb-6'>
              <h3 className='text-lg font-bold mb-5'>Shipping & Billing Information</h3>
              <p><strong>Shipping Address:</strong> {order.shippingMessage}</p>
              {/* <p><strong>Billing Address:</strong> {order.billingMessage}</p> */}
            </div>

            {/* Total Price */}
            <div className='mb-6'>
              <p><strong>Total Price:</strong> ${order.totalPrice}</p>
            </div>

            {/* Order Status */}
            <div className='mb-6'>
              <label className='block mb-2 text-sm font-medium text-gray-700'>Order Status</label>
              <select
                value={order.status}
                onChange={(e) => handleStateChange(e.target.value)}
                className="rounded-md border border-gray-300 p-2"
              >
                {orderStatuses.map(status => (
                  <option key={status} value={status}>
                    {status.charAt(0).toUpperCase() + status.slice(1)}
                  </option>
                ))}
              </select>
            </div>
          </>
        ) : (
          <Loader size="large" />
        )}
      </div>
    </div>
  );
};

export default OrderDetails;
